import { privateGetApi, privatePutApi } from "../../utils/apiHelper";

export const getReports = (payload) => () => {
  return new Promise((resolve, reject) => {
    privateGetApi("/users/reports", payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserProfile = (payload, id) => () => {
  return new Promise((resolve, reject) => {
    privatePutApi("/users/" + id, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
