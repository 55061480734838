import logo from "../../assets/logo-main-light.svg";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import {
  HomeOutlined,
  MailOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography, ConfigProvider, Button, theme } from "antd";

import { useEffect, useState } from "react";
import ReactivateSession from "../../components/modals/reactivateSession";

const { Header, Footer, Content } = Layout;
const { Paragraph } = Typography;

const items = [
  {
    label: "Home",
    key: "",
    icon: <HomeOutlined />,
    disabled: false,
  },
  {
    label: "Campaigns",
    key: "campaigns",
    icon: <MailOutlined />,
    disabled: false,
  },
  {
    label: "Contacts",
    key: "contacts",
    icon: <UserOutlined />,
    disabled: false,
  },
  {
    label: "Settings",
    key: "settings",
    icon: <SettingOutlined />,
    disabled: false,
  },
];

export default function App() {
  const [current, setCurrent] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const navigate = useNavigate();

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  const clearSession = () => {
    localStorage.clear();
    setSessionExpired(false);
    if (current !== "") {
      navigate("/");
    }
  };

  useEffect(() => {
    const path = window.location.pathname.split("/").pop();
    setCurrent(path === "app" ? "" : path);
    const now = new Date().getTime();
    const expireAt = parseInt(localStorage.getItem("expireAt"));
    if (now > expireAt) {
      setSessionExpired(true);
    } else {
      const timer = setInterval(() => {
        if (now > expireAt) {
          clearInterval(timer);
          setSessionExpired(true);
        }
      }, 60000);
    }
  }, [window.location.pathname]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <ReactivateSession
        sessionExpired={sessionExpired}
        setSessionExpired={setSessionExpired}
        clearSession={clearSession}
      />
      <Layout className="app-layout">
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href="/">
              <img
                src={logo}
                alt="Mailinflow"
                height={30}
                style={{ display: "block" }}
              />
            </a>
            <Menu
              theme="dark"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
              overflowedIndicator={<MenuOutlined />}
              style={{ flexGrow: 1, margin: "0 1rem" }}
            />
            <Button
              danger
              type="link"
              icon={<LogoutOutlined />}
              onClick={() => clearSession()}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content>
          {/* <Title level={3}>{items.find((e) => e.key === current).label}</Title> */}
          {/* <FormContact />
          <FormCampaign /> */}
          <Outlet />
        </Content>
        <Footer>
          <Paragraph style={{ textAlign: "center", marginBottom: 0 }}>
            Copyrights 2020 - {new Date().getFullYear()} Redmonk Studios (OPC)
            Pvt Ltd. All rights reserved.
          </Paragraph>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}
