import React, { useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Modal, Typography, Input } from "antd";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { authValidate, authRequest } from "../../redux/actions/authAction";

const { Text, Paragraph } = Typography;

export default function ReactivateSession({ sessionExpired, clearSession }) {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(60);
  const [oneTimePassword, setOneTimePassword] = useState(null);
  const [generateOTP, setGenerateOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  let timer;

  const onSubmit = (value) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    clearInterval(timer);
    setCounter(0);
    if (generateOTP) {
      dispatch(
        authValidate({
          emailAddress: userData.emailAddress,
          oneTimePassword: oneTimePassword || value,
        })
      )
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    } else {
      dispatch(
        authRequest({
          emailAddress: userData.emailAddress,
        })
      )
        .then(() => {
          setGenerateOTP(true);
          setLoading(false);
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
    }
  };

  useEffect(() => {
    if (sessionExpired) {
      timer = setInterval(() => {
        setCounter((prev) => {
          return prev - 1;
        });
      }, 1000);
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (counter <= 0) {
      clearInterval(timer);
      clearSession();
    }
  }, [counter]);

  return (
    <Modal
      title={
        <Text type="danger" strong>
          <WarningOutlined /> "Session Expired"
        </Text>
      }
      centered
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      open={sessionExpired}
      onOk={() => onSubmit(false)}
      onCancel={() => clearSession()}
      cancelText={"Logout" + (counter > 0 ? " (" + counter + ")" : "")}
      okText={generateOTP ? "Login" : "Regenerate OTP"}
      confirmLoading={loading}
    >
      <Paragraph>
        Your session has expired, kindly{" "}
        {generateOTP
          ? "enter one time password sent on your registered email address."
          : "generate one time password to resume session."}
      </Paragraph>
      {generateOTP && (
        <Input.OTP
          placeholder="One Time Password"
          onChange={(value) => {
            setOneTimePassword(value);
            onSubmit(value);
          }}
          size="large"
        />
      )}
    </Modal>
  );
}
