import React, { useEffect } from "react";
import { Tabs, Form, Input, Button, Row, Col, Divider, message } from "antd";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../../redux/actions/userAction";

export default function Settings() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userProfileForm] = Form.useForm();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      userProfileForm.setFieldsValue({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        emailAddress: userData.emailAddress || "",
        phoneNumber: userData.phoneNumber || "",
        companyName: userData.companyName || "",
        streetAddress: userData.address?.streetAddress || "",
        city: userData.address?.city || "",
        pincode: userData.address?.pincode || "",
        state: userData.address?.state || "",
        country: userData.address?.country || "",
        companyWebsite: userData.companyWebsite || "",
      });
    }
  }, [userProfileForm]);

  const onFinish = (values) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    dispatch(
      updateUserProfile(
        {
          firstName: values.firstName || "",
          lastName: values.lastName || "",
          emailAddress: values.emailAddress || "",
          phoneNumber: values.phoneNumber || "",
          companyName: values.companyName || "",
          address: {
            streetAddress: values.streetAddress || "",
            city: values.city || "",
            pincode: values.pincode || "",
            state: values.state || "",
            country: values.country || "",
          },
          companyWebsite: values.companyWebsite || "",
        },
        userData.id
      )
    )
      .then(() => {
        setLoading(false);
        localStorage.setItem(
          "user",
          JSON.stringify({
            id: userData.id,
            firstName: values.firstName || "",
            lastName: values.lastName || "",
            emailAddress: values.emailAddress || "",
            phoneNumber: values.phoneNumber || "",
            companyName: values.companyName || "",
            address: {
              streetAddress: values.streetAddress || "",
              city: values.city || "",
              pincode: values.pincode || "",
              state: values.state || "",
              country: values.country || "",
            },
            companyWebsite: values.companyWebsite || "",
          })
        );
        message.success("User profile successfully updated.", 6);
      })
      .catch((e) => console.log(e));
  };

  const items = [
    {
      key: "profile-settings",
      label: "User Profile",
      children: (
        <Row justify="center" style={{ margin: "1rem 0" }}>
          <Col xs={24} sm={18} md={14} lg={12} xl={10}>
            <Form
              form={userProfileForm}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                firstName: "",
                lastName: "",
                emailAddress: "",
                phoneNumber: "",
                companyName: "",
                streetAddress: "",
                city: "",
                pincode: "",
                state: "",
                country: "",
                companyWebsite: "",
              }}
            >
              {/* Personal Information Section */}
              <h3>Update Personal Information</h3>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input placeholder="Enter your first name" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input placeholder="Enter your last name" />
              </Form.Item>

              <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Enter your email address" disabled />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  { required: true, message: "Please enter your phone number" },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>

              <Divider />
              {/* Organization Information Section */}
              <h3>Organization Information</h3>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  { required: true, message: "Please enter your company name" },
                ]}
              >
                <Input placeholder="Enter your company name" />
              </Form.Item>

              <Form.Item
                label="Company Address"
                name="streetAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter your company address",
                  },
                ]}
              >
                <Input placeholder="Enter your company address" />
              </Form.Item>

              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please enter your city",
                  },
                ]}
              >
                <Input placeholder="City" />
              </Form.Item>

              <Form.Item
                label="Pincode"
                name="pincode"
                rules={[
                  {
                    required: true,
                    message: "Please enter your pincode",
                  },
                ]}
              >
                <Input placeholder="Pincode" />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please enter your state",
                  },
                ]}
              >
                <Input placeholder="State" />
              </Form.Item>

              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please enter your country",
                  },
                ]}
              >
                <Input placeholder="Country" />
              </Form.Item>

              <Form.Item
                label="Company Website"
                name="companyWebsite"
                rules={[
                  { required: false },
                  { type: "url", message: "Please enter a valid URL" },
                ]}
              >
                <Input placeholder="Enter your company website" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
    },
    {
      key: "billing-and-payments",
      label: "Billing and Payments",
      children: "Content of Tab Pane 2",
      disabled: true,
    },
    {
      key: "notification-settings",
      label: "Notifications",
      children: "Content of Tab Pane 3",
      disabled: true,
    },
    {
      key: "email-settings",
      label: "Customize Email",
      children: "Content of Tab Pane 3",
      disabled: true,
    },
  ];

  return (
    <div className="Settings">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
